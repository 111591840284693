/*font import*/
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* font-family: 'Roboto', sans-serif; */
/* font-family: "Dosis", sans-serif; */

* {
  scroll-behavior: smooth;
  letter-spacing: 0.05rem;
  font-family: var(--global-font);
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --form-fonts: "Dosis", sans-serif;
  --global-font: "Roboto", sans-serif;
  --card-background-col-primary: #fff;
  --card-background-col-hovered: #f3f3f3;

  --card-background-col-secondary: #464fa0;
  --card-background-col-black: rgba(44, 44, 44, 0.914);

  --card-background-col-shade-gray: rgba(243, 243, 243, 0.775);
  --card-background-col-misc: rgba(196, 196, 196, 0.775);

  --button-background-col-primary: #fff;
  --button-background-col-secondary: #000;
  --button-background-col-banx: #464fa0;

  --button-background-col-misc: ;

  --font-color-white: #fff;
  --font-color-black: #000;
  --font-color-gray: rgba(143, 143, 143, 0.511);

  --font-size-small: 12px;
  --font-size-normal: 13.5px;
  --font-size-large: 14px;

  --border-col-white: #fff;
  --border-col-black: #000;
  --border-col-gray: rgb(143, 143, 143);

  --border-radius-min: 10px;
  --border-radius-moderate: 15px;
  --border-radius-primary: 25px;
  --border-radius-secondary: 35px;
  --border-radius-others: 50px;
  --border-radius-circle: 99px;

  --shadows: #c4c4c4;
  --shadows-blue-shade: #464fa0;
  --shadows-dark-grey: #c4c4c450;

  --tentative: blue;
  --danger: red;
  --success: rgb(2, 183, 2);
}

select {
  border: none;
  outline: none;
}

input {
  border: none;
  outline: none;
}

textarea {
  border: none;
  outline: none;
  resize: none;
  padding: 2em 0;
}

button {
  border: none;
  outline: none;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: var(--border-radius-min);
}

::-webkit-scrollbar-thumb {
  border-radius: 40px thin;
  background: var(--card-background-col-black);
}

::-webkit-scrollbar-thumb:hover {
  filter: brightness(2.5);
}

/*Global modal styles*/
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5em;
  border-radius: var(--border-radius-moderate);
  width: 100%;
}

.modal-container h3{
  text-align: center;
}

.form-icon {
  font-size: 2em;
  margin: 0.7em 0;
}

.modal-form {
  /* border-bottom: 1px solid var(--card-background-col-black); */
  /* border-top: 1px solid var(--card-background-col-black); */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em 0.5em;
  /* font-family: var(--form-fonts); */
}

.modal-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}

.modal-field-container label {
  font-weight: 600;
  width: 30%;
  font-size: var(--font-size-normal);
}

.modal-field-container .select-field {
  width: 70%;
  padding: 0.8em;
  border-radius: var(--border-radius-moderate);
  border: 1px solid var(--card-background-col-black);
}

.modal-field-container .input-field {
  font-family: var();
  width: 65%;
  padding: 0.8em;
  border-radius: var(--border-radius-moderate);
  border: 1px solid var(--card-background-col-black);
}

.modal-field-container .input-field:read-only{
  background: var(--card-background-col-hovered);
  border: none;
}

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 1em 0 0 0;
  font-size: var(--font-size-normal);
}

.modal-button-container button {
  max-width: fit-content;
  padding: 0.8em 5em;
  color: var(--font-color-black);
  border-radius: var(--border-radius-moderate);
}

.modal-button-container button:hover {
  transform: scale(1.05);
}

.modal-button-container button:nth-child(1) {
  border: 1px solid var(--danger);
}

.modal-button-container button:nth-child(1):hover {
  background: var(--danger);
  color: var(--font-color-white);
}

.modal-button-container button:nth-child(2) {
  border: 1px solid var(--card-background-col-black);
}

.modal-button-container button:nth-child(2):hover {
  background: var(--card-background-col-black);
  color: var(--font-color-white);
}
